    body {padding:0 10px; font-family: 'Gabarito', sans-serif; background: url('/src/assets/mainbg2.jpg') #000; background-attachment: fixed; font-size:11px; background-size: cover;}
    .headerTable {background-color: rgb(165, 199, 123); color: rgb(255, 255, 255); border: none;}
    .headerTableClimbs {background-color: rgb(232, 138, 24); color: rgb(255, 255, 255); border: none;}
    header p { text-align: center;  font-size: 12px; color: white; color: black; background-color: #fce350; display: block;  width: 190px; margin: 20px auto;}
    .alignCenter {text-align: center !important;}
    #runners {border-collapse: collapse;width: 100%; margin:0 auto; font-size: 12px; margin-bottom:20px}
    #runners td, #runners th {border: 1px solid #000;padding: 5px; height:25px}
    #runners th { height:0px; font-size:11px}
    .holder img { margin: 0 auto; display:block}
    .realtime img {width: 450px; margin: 0 auto; display:block}
    #runners img {height: 10px; float: right; position: relative;top: 4px;}
    .winner {background-color: #9ccb3b;color: black;}
    .dnf {color: grey !important;}
    tr.totalTime {background-color: #444444 !important}
    /* table tbody {border-top: 1px solid #222222} */
    select.selectedAge { margin-left: 16px;}
    h1, h2, h3 { font-size: 24px; margin:20px 0 0 0; text-align: center; color:#ffffff; letter-spacing:1px; font-weight: normal }
    h2, h3 { font-size: 14px;  margin:0 0 20px 0}
    h1 {background-color: #25b3e8; display: block; margin: 20px auto 0 auto;padding: 0px; width: 192px !important; color: black;}
    .regArea h1 {width: 219px !important;}
    .tenText {margin-bottom: 30px;}
    .footerHolder {width: 100%; margin: 15px auto; color: white;font-size: 15px; text-align: center; float: left; }
    .footerText {display: block; margin: 0 auto; width: 214px; background-color: #25b3e8; padding: 2px; color: black; font-size: 14px;}
    .footerHolder a {color: #182204}
    .tabiIntros {width: 100%; margin-bottom: 10px; display: block; height: 20px;}
    .tabiIntros .races {width: 40%; float:left; font-size: 12px; color: #f8f8f8; margin-left: 2px;}
    .tabiIntros .climbs {width: 40%; float:right; font-size: 12px;  text-align: right;color: #f8f8f8; margin-right: 2px; }
    #runners tr:nth-child(even){background-color: rgba(0, 0, 0, 0.8);; color:#cccccc}
    #runners tr:nth-child(odd){background-color: rgba(0, 0, 0, 0.65);; color:#cccccc}
    #runners tr:hover {background-color: #111111; color:#ffffff}
    #runners th {padding-top: 8px;padding-bottom: 9px;text-align: left;color: black; border:1px solid #000; }
    .instructions {float: right; color: #ababab; margin-top: 16px; font-size: 12px;font-family: Gabarito, sans-serif;}
    #stages tr:nth-child(even){background-color: #222222; color:#cccccc}
    #stages tr:nth-child(odd){background-color: #111111; color:#cccccc}
    #stages tr:hover {background-color: #111111; color:#ffffff}
    #stages th {padding-top: 8px;padding-bottom: 9px;text-align: left;color: black; border:1px solid #febb00; color:#fff}
    .holder {max-width: 1900px; margin: 20px auto}
    .tabHolder {max-width:450px;  width:450px; margin:0 auto}
    .tenkTab, .halfTab, .fullTab, .tenkTabWider { margin-left: 1px;height:28px; padding:8px 8px 0 8px; text-align:center; background-color:#000000; color:#ffffff; display:block; float:left; margin-right:5px; margin-top: 20px; font-size: 18px; color:#ffffff}
    /* .fullTab, .halfTab {float: right;}
    .halfTab {margin-right: 1px;} */
    .tenkTab {width:125px; background-color: #444444}
    .tenkTabWider {width:105px; background-color: #444444}
    .halfTab {width:45px; background-color:#444444}
    .fullTab {width:105px; background-color:#fcd338}
    .tenkTab.active, .tenkTabWider.active {background-color:#cfc7b0; color:#000000}
    .halfTab.active {background-color:#cfc7b0 ; color:#000000}
    .fullTab.active {background-color:#fbd436 ; color:#000000}
    .registered {color: #ff7f21}
    .tenText .brandColor {color: #9ccc3b; background-color: rgb(0, 0, 0, 0.7); padding: 3px 6px;}
    .halfText .brandColor  {color:#ff7f21}
    .fullText .brandColor  {color:#ff7f21}
    .modalContent {width: 100%; padding: 0 5px 5px 5px}
    .modalContent h2 {color: #ee2087; padding: 3px 5px; margin: 0 0 15px 0; display: inline-block; font-size: 18px;}
    .section {width:100%; font-size: 14px;}
    .col {width:50%; float:left; color: #b3b3b3}
    .colRight {width:50%; float:right; color: whitesmoke;}
    .closeButton {color: white;font-size: 25px;text-align: right; width: 100%;font-family: sans-serif;font-weight: bold; color: #fff; float: right; background-color: transparent; border: none;}
    .tenBlock, .halfBlock, .fullBlock {max-width:450px;  width:100%; margin:0 auto}
    .tenBlock #runners td, .tenBlock #runners th {border: 1px solid #3c3c3c;    font-weight: normal;}
    .halfBlock #runners td, .halfBlock #runners th {border: 1px solid #b42d71}
    .fullBlock #runners td, .fullBlock #runners th {border: 1px solid #b42d71}
    .sectionHeader {width:100%; float:left; color:#ffffff; text-align:center}
    .noFinishers {text-align:center; color:#ffffff; margin:20px}
    .time {width:40px}
    .age {width:50px}
    .logo {width:150px !important; display:inline-block}
    .logoReg {width:80px !important; display:inline-block}
    .tableArea {width:100%;}
    .icons {width:20px !important}
    .fem {color:#dddddd}
    .blackCell {background-color: #000;}
    .male{color:#dddddd}
    .grey {color:#666666}
    .footer {width:100%; clear:both}
    p {color: #dddddd; text-align: center; }
    .realtime {color:#888888; text-align: center; width: 100%; clear:both; margin:20px 0; float:  left;}
    .realtime a {color:#888888}
    input.searchQuery {font-size: 36px;width: 150px;text-align: center;text-transform: uppercase;}
    .M50:first-of-type td.ageGr {background-color: red !important; font-weight: bold !important} 
    .btnlink  {color: black;}
    .inline-inner {padding: 20px;}
    h4 {margin-bottom: 25px;font-weight: 600;color: #000;font-size: 1.2em;}
    p {line-height: 1.7em;margin-bottom: 2em;color: #dddddd;}
    .fieldSet {width: 96%;  margin:5px 2%;  float: left; font-size: 11px; height: 65px; display:block; background-color: #2b2b2b; color:#c3c3c3 }
    .error {color:tomato; font-size: 18px; margin: 10px 0;}
    .fieldInput .fieldInputPin {width:100%}
    select {font-size: 28px; color: black !important; margin-left:30%; background-color: #8b8b8b; font-size: 20px ;}
    select.selectedAge { margin-left: 16px;}
    .btnbck { border-radius: 5px; background-color: orange; color:black; border:none; padding:10px; font-size: 18px; text-align: center; margin: 20px auto; display: block;}
    .close {width: 99%; text-align: right; color: #ffffff; font-size: 28px; padding: 10px 20px 0 0;}
    .blueBand, .redBand {width:46%; margin:5px 2%; float: left; height: 65px;}
    .redBand {background-color:#db6b62}
    .blueBand {background-color: #2c72c3;}
    .redbg {background-color: red; color: white;}
    .bluebg {background-color: blue; color: white;}
    .timeCol{width: 56px; text-align: center;}
    .smallColl, .rAge, .rNumber {width:54px; text-align: center !important;}
    .editRow {background-color: #787c85; }
    tr th{ background-color: #ee2087; border:1px solid #ee2087; color:#fff}
    .headerTableClimbs th{ background-color: #fbd436 !important; border:1px solid #000; color:#fff}
    .cancelBtn, .submitBtn {width:96%; margin:5px 2%;float: left; text-align: center;}
    .totalRunners {text-align: center; color: white;font-size: 16px; margin: 10px 0; letter-spacing: 1px; line-height: 30px;}
    .submitBtn .btn {background-color: #9ccc3b; border: none;width: 100%; color: black !important;font-weight: bold;}
    .cancelBtn .btn {background-color: #66221d; color: #aaa;}
    .btn {text-transform: uppercase; text-align: center; background-color: #161d27; color:white; width:80%;  display: block; padding:10%; font-size: 14px; letter-spacing: 1px;}
    .editBG {background-color: black }
    .raceNumber, .bibNumber {width: 96%; margin: 20px 2%; font-size: 70px; color:#fff;text-align: center;border: none;border-bottom: 1px solid #4f5e64; background: transparent; letter-spacing: 1px}
    .fieldLabel {width: 96%; margin:5px 2%; font-size: 11px; color: #8c8c8c; font-weight: normal;  letter-spacing: 1px}
    .fieldLabelPin {width: 86%; margin:5px 7%; font-size: 11px; color: #8c8c8c; font-weight: normal;  letter-spacing: 1px}
    .raceNumberHolder, .runnersNameHolder {width: 100%; float: left; }
    .leftcolHolder, .runnersNameHolder {width: 48%; float: left; }
    .runnersNameHolder {margin: 0 2% 0 0}
    .leftcolHolder {margin: 0 0 0 2%}
    .raceNumberHolder {height: 140px; margin:5px 2%;  background-color: #2b2b2b; width:96%}
    .runnersNameHolder input[type="text"], .leftcolHolder input[type="text"], .loginHolder  input[type="text"], .fullWidthField  input[type="text"]{width: 96%; margin: 8px 5% 9px 2%; font-size: 18px; color:#fff;border: none;border-bottom: 1px solid #4f5e64; background: transparent; letter-spacing: 1px}
    .fullWidthField {width:98% ;margin:0 1%}
    .fieldInputPin input[type="text"] {width:80%; margin:0 10%; font-size:22px;}
    .loginHolder p {color: #ffffff;}
    .fieldInputPin input[type="text"] {width:80%; margin:0 10%; font-size:22px;}
    select {margin-left:5%; background-color: #8b8b8b; font-size: 20px ;}
    .raceColourHolder img {height: 50px; display: block; padding-top: 10%}
    .searchClear img {height: 20px; display: block;}
    .addRunner img {height: 50px; display: block;}
    .searcArea {width: 240px; margin: 30px auto 70px auto}
    .searchQueryNew {width: 100%; height: 30px; font-size: 22px; background-color: #2b2b2b; border:none; color:white; }
    .searchField {width: 160px;height: 30px;  float: left; display: block; }
    .searchClear {width: 40px; height: 30px; float: left; display: block; }
    .searchIntro {text-align: center; color: grey; letter-spacing: 1px; margin: 5px 0}
    h1 {font-size: 14px; line-height: 28px; margin-bottom: 28px}
    .fontInc {font-size: 24px; text-transform: uppercase}
    .addRunner {width: 50px; margin: 0 auto; position: fixed; right:10px; top:10px}
    .fieldCheck {font-size: 16px; padding: 6px;}
    .checkboxPadding {padding:12px; font-size: 14px;}
    .checkboxPadding label {position: relative; top:-10px}
    input[type=checkbox] {width: 30px; height: 30px;}
    input[type="color"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="text"],
    select:focus,
    textarea {padding: 0; border-radius: 0}
    .runnerCol {min-width: 110px}

    .race {background-color: #601337 !important;}

    .raceNumber, .bibNumber {width: 96%; margin: 20px 2%; font-size: 70px; color:#fff;text-align: center;border: none;border-bottom: 1px solid #4f5e64; background: transparent; letter-spacing: 1px}

    
    .loginArea input {width: 80%;height: 30px; clear: both; float: left; margin: 10px 10% 0 10%; padding: 5px;font-size: 14px;border-radius: 5px; border: none;}

    .loginArea .submitButton {margin-left: 10%; background: #ee2087 !important; margin-top: 10px; }

    .loginArea h1 { margin-top: 50px; height: 25px;}

    .loginPara {margin: 30px 10% 10px 10%; font-size: 16px; text-align: left !important;}

    .loginPara p {color: #25b3e8 !important; font-size: 16px;}

    .searchHolder {width: 300px; display: block; margin: 0 auto; height: 50px;}

    .emptyField {float: left; margin-left: 4px; color: white; background: black; border: none;}

    .filterField {width: 200px; float: left; margin-left: 38px; font-size: 16px;; display: block; padding: 0 10px !important; height: 30px; background-color: #2b2b2b; border: 1px solid #96c439; color: #999999;}
    .registered {color: #ff7f21;background-color: black; width: 130px; margin: 0 auto; padding: 5px;}
  
    a {
      text-decoration: none;
  }

  .adminRunners td {font-size: 17px}
  .adminRunnersStart td {font-size: 15px}

  .loginLink {margin: 20px auto 0 auto;font-size: 13px;color: white !important;text-align: center;display: block;text-decoration: underline;background-color: black; padding: 5px;float: left;margin-right: 5px;}

    select {font-size: 28px; color: black !important;}

    .newRunner {width: 100px; margin: 20px auto; border-radius: 5px; display: block; color:white; background-color: #ee2087; border: none; height: 30px;}

    @media  (max-width: 1200px) {
       body {background: url('/src/assets/mainbg2_med.jpg') #000; background-attachment: fixed;}
    }
 
    @media  (max-width: 570px) {
       .tenBlock, .halfBlock, .fullBlock, .tabHolder { max-width:100%;  width:100%}
         body {background: url('/src/assets/mainbg2_mob.jpg') #000; background-attachment: fixed;}
    }

    @media  (max-width: 440px) {
      .realtime img {width: 320px; }
      .tenBlock, .halfBlock, .fullBlock { padding:0px; max-width:100%; float:left; width:100%; margin:0 0 20px 0}
      #runners {font-size: 12px}
      .time {width:30px}
      .age {width:40px}
    }

    @media only screen and (max-width: 424px) {
      .checkboxPadding {padding:18px 0 0 14px; font-size: 12px;}
      .hiddenSmall {display: none}
      .tenkTab {width:125px; background-color: #444444; font-size: 14px;}
      .halfTab, .tenkTabWider {width:45px; background-color:#444444; font-size: 14px;}
      .fullTab {width:105px; background-color:#444444; font-size: 14px;}
    }


    @media only screen and (max-width: 395px) {
      .checkboxPadding {padding:18px 0 0 14px; font-size: 12px;}
      .hiddenSmall {display: none}
      .tenkTab {width:125px; background-color: #444444; font-size: 13px;}
      .halfTab, .tenkTabWider {width:45px; background-color:#444444; font-size: 13px;}
      .fullTab {width:68px; background-color:#444444; font-size: 13px;}
    }

    @media only screen and (max-width: 340px) {
      .checkboxPadding {padding:18px 0 0 14px; font-size: 12px;}
      .hiddenSmall {display: none}
      .tenkTab {width:125px; background-color: #444444; font-size: 13px;}
      .halfTab, .tenkTabWider {width:45px; background-color:#444444; font-size: 13px;}
      .fullTab {width:68px; background-color:#444444; font-size: 13px;}
    }
