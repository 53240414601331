
.adminBlock {width: 31%; background-color: #222222; height: 120px; margin: 1%; border: 1px solid #555555; float: left;}
.adminArea h1, .adminArea h2 {color: white}
.adminArea h1 {font-size: 36px; line-height: 20px;}
.adminArea h2 {font-size: 19px;}
.adminArea .Active {border: 1px solid green; background: #284c24;}
.adminArea .DNF, .adminArea .DNS {border: 1px solid #c21717;; background: #751717;}
.adminArea .Winner {border: 1px solid yellow; background: #8e8e0a; color: black}

.ReactModalPortal {text-align: left;}
.ReactModalPortal label  {margin: 5px 0 0 0;float: left; font-size: 12px;}

.ReactModalPortal .closeButton {float: right; margin-bottom: 10px;  color:white; border: none; border-radius: 3px; padding: 3px; width: 25px;}

.ReactModalPortal .submitButton, .submitButton {background-color: green; color: white; border-radius: 5px; padding: 12px; border:none}

.adminArea .firstName {color: #eeeeee; font-size: 14px;}
.adminRunners {font-size: 15px;}

.adminArea .headerTitle {height: 34px; padding: 4px 10px; font-size: 18px;margin-right: 5px; float: left;}
.adminArea .order {color: white; display: block; width: 90px; float: left; margin-left: 5px; font-size: 16px;}
.adminArea .buttonHolder {margin: 5px 0; width: 100%;display: block;height: 40px; float: left;}
.adminArea .buttonHolder button {background-color: #efbb00 !important; border: none; border-radius: 5px; color: black !important; text-decoration: none; float: left;}
.loginArea {margin: 10px auto; max-width: 500px; text-align: left; }
.passwordField {margin-bottom: 10px;}
.adminArea .activeSort {color: white;}


.loginArea input {width: 80%;height: 30px; clear: both; float: left; margin: 10px 10% 0 10%; padding: 5px;font-size: 14px;border-radius: 5px; border: none;}
.loginArea .submitButton {margin-left: 10%; background: #ee2087 !important; margin-top: 10px; }
.loginArea h1 {color: #9ccc3b !important;     margin-top: 50px; }
.loginPara {margin: 30px 10% 10px 10%; font-size: 16px; text-align: left !important;}
.loginPara p {color: black; font-size: 16px; background-color: white; display: inline; padding: 1px;}

.uploadFile {background-color: #8c989e; display: block; width: 200px !important; color: white; }
.uploadButton {clear: both;float: left; margin: 20px 0 0 50px; width: 100px; height: 37px; background-color: #ff00bf; border: none;  border-radius: 3px;  color: white;  }
